<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Warehouse</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="warehouseUpdate">
              <div class="card-body">
                <div class="form-group">
                  <label>Title</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Choose One</label>
                  <select class="custom-select" v-model="form.venue_id">
                    <option v-for="v in venue" :key="v.id" :value="v.id">{{
                      v.title
                    }}</option>
                  </select>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "FormVenue",
  data() {
    return {
      name: "Form Warehouse",
      form: {
        name: "",
        venue_id: "",
      },
      venue: [],
      errors: {},
      isSubmitting: false,
    };
  },
  created() {
    let id = this.$route.params.id;
    axios
      .get(this.$store.state.api + "warehouse/" + id, {
        headers: { Authorization: this.$store.state.token },
      })
      .then((res) => (this.form = res.data.data))
      .catch((err) => console.log(err));
  },
  mounted() {
    axios
      .get(this.$store.state.api + "venue", {
        headers: { Authorization: this.$store.state.token },
      })
      .then((res) => (this.venue = res.data.data))
      .catch((err) => console.log(err));
  },
  methods: {
    warehouseUpdate() {
      this.isSubmitting = true;
      let id = this.$route.params.id;
      let formData = new FormData();
      formData.set("name", this.form.name);
      formData.set("venue_id", this.form.venue_id);
      axios
        .post(this.$store.state.api + "warehouse/update/" + id, formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your warehouse has been updated!");
          this.$router.push({ name: "Warehouse" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
<style>
.btn-primary {
  color: #fff;
}
</style>
